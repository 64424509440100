










import PageTemplate from "@/components/PageTemplate.vue";
import PinnedMessages from "@/components/PinnedMessages/PinnedMessages.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    PinnedMessages,
  },
})
export default class PinnedMessageView extends Vue {
  RouteName = RouteName;
}
