



















































































































































import {
  DAY_IN_MINUTES,
  DAY_IN_MS,
  HOUR_IN_MINUTES,
  HOUR_IN_MS,
  MINUTE_IN_MS,
  WEEK_IN_MINUTES,
} from "@/helpers/consts";
import { MessageRouterPayload } from "@/types";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import ButtonMenuItemList from "../ButtonMenuItemList.vue";
import ConfirmDialog from "../ConfirmDialog.vue";
import {
  AddPinnedMessageRequest,
  Channel,
  PinnedMessage,
} from "@/store/generalSettings/types";

@Component({
  components: {
    ButtonMenuItemList,
    ConfirmDialog,
  },
})
export default class PinnedMessages extends Vue {
  @Getter("generalSettings/allChannels")
  readonly allChannels!: Channel[];

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  @Getter("generalSettings/pinnedMessages")
  readonly pinnedMessages!: PinnedMessage[];

  @Action("generalSettings/getChannelsList")
  getChannelsList!: () => Promise<void>;

  @Action("generalSettings/getPinnedMessagesList")
  getPinnedMessagesList!: () => Promise<void>;

  @Action("generalSettings/addPinnedMessage")
  addPinnedMessage!: (data: AddPinnedMessageRequest) => Promise<void>;

  @Action("generalSettings/deletePinnedMessage")
  deletePinnedMessage!: (id: string) => void;

  isLoading = true;

  message = "";
  selectedChannels: Channel[] = [];
  duration = this.$t("10 minutes");

  durations = [
    this.$t("10 minutes"),
    this.$t("30 minutes"),
    this.$t("1 hour"),
    this.$t("12 hours"),
    this.$t("24 hours"),
    this.$t("Indefinitely"),
  ];
  search = "";

  dialog = false;
  pinnedMessageToDelete: PinnedMessage | null = null;

  headers = [
    {
      text: this.$t("Pinned Messages"),
      value: "pinnedMessage",
      class: "pinned-message",
      width: "40%",
    },
    {
      text: this.$t("Time remaining"),
      value: "validUntil",
      filterable: false,
    },
    {
      text: this.$t("pinnedMessages.broadcastChannelLabel"),
      value: "channelId",
    },
    {
      text: this.$t("Pinned by"),
      value: "userInfo.displayName",
    },
    { text: this.$t("dataTable.actions"), value: "id" },
  ];

  @Watch("broadcasts", { immediate: true })
  onBroadcastChange(): void {
    // get data once broadcasts change
    if (this.broadcasts.length > 0) {
      this.getData();
    }
  }

  async getData(): Promise<void> {
    await Promise.allSettled([
      this.getChannelsList(),
      this.getPinnedMessagesList(),
    ]);
    this.isLoading = false;
  }

  get messageRouterPayload(): MessageRouterPayload {
    const broadcastIds: Set<string> = new Set();
    const channelIds: Set<string> = new Set();

    for (const channel of this.selectedChannels) {
      broadcastIds.add(channel.broadcast.broadcastId);
      channelIds.add(channel.id);
    }

    return {
      tenantWide: broadcastIds.size + channelIds.size === 0,
      broadcastIds: Array.from(broadcastIds),
      channelIds: Array.from(channelIds),
    };
  }

  get isDisabled() {
    return (
      !this.message ||
      this.message.length > 240 ||
      this.selectedChannels.length === 0
    );
  }

  get pinDurationTime() {
    if (this.duration === this.$t("10 minutes")) {
      return MINUTE_IN_MS * 10;
    } else if (this.duration === this.$t("30 minutes")) {
      return MINUTE_IN_MS * 30;
    } else if (this.duration === this.$t("1 hour")) {
      return HOUR_IN_MS;
    } else if (this.duration === this.$t("12 hours")) {
      return HOUR_IN_MS * 12;
    } else if (this.duration === this.$t("24 hours")) {
      return DAY_IN_MS;
    } else if (this.duration === this.$t("Indefinitely")) {
      return -1;
    } else {
      return 1;
    }
  }

  async pinMessage() {
    await this.addPinnedMessage({
      pinnedMessage: this.message,
      durationMs: this.pinDurationTime,
      messageRouterPayload: this.messageRouterPayload,
    });

    this.message = "";
    this.selectedChannels = [];
    this.duration = this.$t("10 minutes");
  }

  pinTimeRemaining({ validUntil, dateCreated }: PinnedMessage): string {
    if (validUntil === dateCreated) return "∞";

    const timeRemainingMinutes = (validUntil - Date.now()) / MINUTE_IN_MS;

    const durationBreakdown = {
      weeks: Math.floor(timeRemainingMinutes / WEEK_IN_MINUTES),
      days: Math.floor(
        (timeRemainingMinutes % WEEK_IN_MINUTES) / DAY_IN_MINUTES
      ),
      hours: Math.floor(
        ((timeRemainingMinutes % WEEK_IN_MINUTES) % DAY_IN_MINUTES) /
          HOUR_IN_MINUTES
      ),
      minutes: Math.floor(
        ((timeRemainingMinutes % WEEK_IN_MINUTES) % DAY_IN_MINUTES) %
          HOUR_IN_MINUTES
      ),
    };

    const durationPieces: string[] = [];

    for (const [timeUnit, value] of Object.entries(durationBreakdown)) {
      if (value > 0) {
        durationPieces.push(this.$tc(`polls.${timeUnit}`, value, { value }));
        if (durationPieces.length >= 2) break;
      }
    }

    return durationPieces.join(" ");
  }

  showDeleteDialog(item: PinnedMessage) {
    this.pinnedMessageToDelete = item;
    this.dialog = !this.dialog;
  }

  deleteSelectedPinnedMessage() {
    this.dialog = false;
    if (this.pinnedMessageToDelete) {
      this.deletePinnedMessage(this.pinnedMessageToDelete.id);
    }
  }

  pinnedMessagesBroadcastChannelMenu(index: number) {
    const messageRouter = this.pinnedMessages[index].messageRouter;
    const list: string[] = [];
    for (let i = 0; i < messageRouter.channels.length; i++) {
      const index = messageRouter.broadcasts.findIndex(
        (broadcast: any) =>
          broadcast.broadcastId === messageRouter.channels[i].broadcastId
      );
      if (index !== -1) {
        list.push(
          `${messageRouter.broadcasts[index].broadcastName} - ${messageRouter.channels[i].channelName}`
        );
      }
    }

    return list;
  }
}
